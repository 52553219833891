import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Layout } from "../components/common";
import Footer from "components/Footer/Footer.jsx";
import { MetaData } from "../components/common/meta";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PortableText from "components/common/PortableText.js";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CardHeader from "components/Card/CardHeader.jsx";

import postStyle from "assets/jss/material-kit-react/views/landingPageSections/postStyle.jsx";
import "typeface-roboto";
import "typeface-roboto-slab";
/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ classes, data, location }) => {
    const post = data.sanityPost;
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/php8vfy.css"
                />
            </Helmet>
            <MetaData data={data} location={location} type="article" />
            <Layout isHome={false} mainImage={data.sanityPost.mainImage}>
                <div>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={12}
                            md={10}
                            style={{ margin: "0 auto" }}
                        >
                            <Card plain>
                                <CardHeader>
                                    <h1 className={classes.title}>
                                        {data.sanityPost.title}
                                    </h1>
                                </CardHeader>
                                <CardBody>
                                    {data.sanityPost.slug.current ===
                                    "calendar" ? (
                                            <iframe
                                                src={
                                                    "https://calendar.google.com/calendar/embed?src=ssocdallas%40gmail.com&ctz=America%2FChicago"
                                                }
                                                style={{ borderWidth: 0 }}
                                                width="100%"
                                                height="600"
                                                frameborder="0"
                                                scrolling="no"
                                            ></iframe>
                                        ) : data.sanityPost.slug.current ==
                                      "map-direction-google" ? (
                                                <iframe
                                                    src={
                                                        "https://maps.google.com/maps?q=st.Seraphim%20cathedral%20Dallas&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                                    }
                                                    style={{ borderWidth: 0 }}
                                                    width="100%"
                                                    height="600"
                                                    frameBorder="0"
                                                    scrolling="no"
                                                ></iframe>
                                            ) : data.sanityPost.slug.current ==
                                      "360-virtual-tour-of-our-cathedral" ? (
                                                    <iframe
                                                        src={
                                                            "https://orthodox360.com/tours/stseraphim-dallas/?fbclid=IwAR0MPZ7L09a28OKeOZXdJWDx844AlJMrRoyFxxCAPwlKJONit4aGLRJiRIc"
                                                        }
                                                        style={{ borderWidth: 0 }}
                                                        width="100%"
                                                        height="600"
                                                        frameBorder="0"
                                                        scrolling="no"
                                                    ></iframe>
                                                ) : (
                                                    <PortableText
                                                        blocks={data.sanityPost._rawBody}
                                                    />
                                                )}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </Layout>
            <Footer/>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        sanityPost: PropTypes.object.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired
};

export default withStyles(postStyle)(Post);

export const postQuery = graphql`
    query($slug: String!) {
        sanityPost(slug: { current: { eq: $slug } }) {
            ...SanityPostFields
        }
    }
`;
